import React, { Fragment, useEffect, useState, useMemo } from "react";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";
import Axios from "axios";
import { Helmet } from 'react-helmet';
import _ from 'lodash';

const RentalManagement = ({ data }) => {
  const [activeService, setActiveService] = useState(0);
  const [serviceImage, setServiceImage] = useState();
  const [email, setEmail] = useState('');
  const [emailResponse, setEmailResponse] = useState('');

  const rentalManagement = data;
  const header_descriptor = rentalManagement?.allContentfulDescriptor?.nodes?.find((item) => item?.contentful_id === '1JcGAG64PhwKELdQHxA5v8');
  const fullHouse_descriptor = rentalManagement?.allContentfulDescriptor?.nodes?.find((item) => item?.contentful_id === '4wh0qqrx9wbLR9t2KnTMcO');

  function getWindowDimensions() {
    const isBrowser = () => typeof window !== "undefined"
    if (isBrowser()) {
      const { innerWidth: width, innerHeight: height } = window;
      if (width <= 414) {
        return true;
      } else {
        return false;
      }
    }
  };

  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    function handleResize() {
      setIsMobile(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (rentalManagement) {
      const image = isMobile ? rentalManagement?.allContentfulRentalManagementServices.nodes[0].mobile_image
        .fluid :
        rentalManagement?.allContentfulRentalManagementServices.nodes[0].image
          .fluid;
      setServiceImage(image);
    }
  }, []);

  const blogs = rentalManagement?.allContentfulBlog?.nodes;
  const oderedBlogData = _.orderBy(blogs, [blogs => blogs.title], ['desc']);

  useMemo(() => {
    if (isMobile) {
      if (rentalManagement) {
        setActiveService(0);
        const image = rentalManagement?.allContentfulRentalManagementServices.nodes[0]?.mobileImage
          ?.fluid;
        setServiceImage(image);
      }
    } else {
      setActiveService(0);
      const image = rentalManagement?.allContentfulRentalManagementServices?.nodes[0]?.image
        ?.fluid;
      setServiceImage(image);
    }
  }, [isMobile]);

  const connectNowHandler = (event) => {
    event.preventDefault();
    if (email) {
      const body = {
        "email": email
      };

      Axios.post("https://api.lqam.com/users/send-subscribe-user-request", body)
        .then(response => {
          if (response?.data?.statusCode === 200) {
            setEmailResponse('Your request has been sent successfully');
          }
        }).catch(err => {
        })
    }

  };

  const changeActiveService = (flag, data) => {
    setServiceImage(isMobile ? data?.mobileImage?.fluid : data.image.fluid);
    setActiveService(flag);
  };

  return rentalManagement ? (
    <Fragment>
      <Helmet>
        <title>Luxury Rental Management</title>
      </Helmet>
      <BackgroundImage
        className="banner luxury-care-banner"
        style={{ backgroundAttachment: 'scroll' }}
        Tag="section"
        fluid={isMobile ? rentalManagement?.allContentfulHeroHeader?.nodes[0]?.mobileImage?.fluid : rentalManagement?.allContentfulHeroHeader?.nodes[0]?.headerImage?.fluid}
      >
        <div className="container">
          <div className="banner-wrapper">
            <h1 className="heading text-blue br-none rental-management-hero-image-title">
              {
                rentalManagement?.allContentfulHeroHeader?.nodes[0]
                  ?.headerTitle
              }
            </h1>
          </div>
        </div>
      </BackgroundImage>

      <section className="come-home bg-gray">
        <div className="container">
          <div className="come-home-wrapper">
            <div className="title-wrapper text-left">
              <h2 className="theme-title text-center">
                {
                  header_descriptor?.title
                }
              </h2>
              <p className="theme-desp text-center">
                {
                  header_descriptor?.description
                }
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="luxury-benifit-listings-main">
        <div className="container">
          <div className="row luxury-benifit-listings">
            {rentalManagement.allContentfulRentalManagementSuccessList?.nodes?.map(
              (item) => {
                return (
                  <div key={item.id} className="col-sm-3 benifit-list">
                    <div className="benefit-icon text-center">
                      <Img fixed={item.icon.fixed} alt="icon exclusive home" />
                    </div>
                    <div className="benefit-content">
                      <h3 className="benefit-title text-center text-blue">
                        {item.title}
                      </h3>
                      <p>{item.subTitle}</p>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>

      <section className="luxury-care-services bg-lightgray">
        <div className="container">
          <div className="row bg-white">
            <div className="col-md-6 care-services-list">
              <div className="service-accordion">
                <div className="accordion-container">
                  {rentalManagement?.allContentfulRentalManagementServices.nodes.map(
                    (item, index) => {
                      return (
                        <div
                          key={item.id}
                          className={`${activeService === index ? "set active" : "set"
                            }`}
                        >
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => changeActiveService(index, item)}
                          >
                            {item.title.toString().toUpperCase()}
                            <i
                              className={`fa fa-${activeService === index ? "minus" : "plus"
                                }`}
                            ></i>
                          </a>
                          <div className="content">
                            <p>{item.subTitle}</p>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 care-service-banner">
              {serviceImage ? (
                <Img
                  fluid={serviceImage}
                  fadeIn={true}
                  loading="eager"
                  durationFadeIn={1}
                  alt=""
                  // onStartLoad={()=> {return <div>Test</div>}}

                  style={{
                    width: "100%",
                    maxHeight: "360px",
                    minHeight: "360px",
                    borderRadius: "3px",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="connect-with contact-us luxury-care-connect">
        <div className="container">
          <div className="connect-with-wrapper">
            <h2 className="theme-title text-center">
              {
                rentalManagement?.contentfulConnectHeaders?.title
              }
            </h2>
            <form className="connect-from" onSubmit={(e) => connectNowHandler(e)}>
              <div className="form-field">
                <input
                  type="text"
                  placeholder="I want 5-Star Concierge Service"
                  className="form-control"
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                {
                  emailResponse ? <p style={{ marginTop: 4 }}>{emailResponse}</p> : null
                }
              </div>
              <div className="submit-btn">
                <input
                  type="submit"
                  className="theme-button"
                  value="CONNECT NOW"
                />
              </div>
            </form>
          </div>
        </div>
      </section>

      <BackgroundImage
        className="luxury-house-banner"
        style={{ backgroundAttachment: 'scroll' }}
        Tag="section"
        fluid={isMobile ? fullHouse_descriptor?.mobileImage?.fluid : fullHouse_descriptor?.image?.fluid}
      >
        <div className="container text-center" style={{ maxWidth: "700px" }}>
          <h2 className="theme-title">
            {
              fullHouse_descriptor?.title
            }
          </h2>
          <p className="text-blue br-none">
            {
              fullHouse_descriptor?.description
            }
          </p>
        </div>
      </BackgroundImage>

      <section
        className="about blog-posts rental"
      // style={{
      //   background: "url('/img/luxury-care-need-bg.png')",
      //   backgroundSize: '100% 100%',
      // }}
      >
        <div className="container">
          <div className="about-wrapper">
            <div className="row">
              {oderedBlogData &&
                oderedBlogData?.length > 0 &&
                oderedBlogData?.map(
                  (item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="col-md-6 about-col" id="property">
                          <Link to={`/${item?.redirectLink}/`}>
                            <span className="img">
                              <Img fluid={item?.image?.fluid} alt="Property Care" />
                            </span>
                            <div className="title-wrapper">
                              <h2 className="theme-title">{item?.title}</h2>
                              <p className="theme-desp">{item?.description}</p>
                            </div>
                            <Link to={`/${item?.redirectLink}/`} className="more-link">
                              Learn More
                            </Link>
                          </Link>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  ) : (
    <Fragment />
  );
};

export default RentalManagement;
