import React from "react";
import { graphql } from "gatsby";
import RentalManagement from "../components/rental-management";

const RentalManagementPage = ({ data }) => {

  return <RentalManagement data={data} />;
};

export default RentalManagementPage;

export const query = graphql`
  {
    allContentfulNavigationSliderContent {
      nodes{
        title{
          title
        }
        redirectUrl,
        link
      }
    }

    contentfulConnectHeaders(contentful_id: {eq: "1HnYeoPXtEmSVOYplO9cYy"}) {
     title
    }

    allContentfulDescriptor(filter: {contentful_id: {in: ["4wh0qqrx9wbLR9t2KnTMcO","1JcGAG64PhwKELdQHxA5v8"]}}) {
      nodes {
        description
        title
        contentful_id
        image {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyContentfulFluid
          }
        }
        mobileImage {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }

  allContentfulBlog(
      filter: {contentful_id: {in: ["4k8zebCNLBqASLqKBEtARg","2qsCxN7dCdusZDJ5ae1yBa"]}}
      ) {
        nodes {
          title
          redirectLink
          description
          contentful_id
          image {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
        }
  }
   allContentfulRentalManagementServices {
    nodes {
      id
      title
      subTitle
      image {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      mobileImage {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
    allContentfulRentalManagementSuccessList(sort: {order: ASC, fields: createdAt}) {
    nodes {
      subTitle
      id
      title
      icon {
        fixed(width: 150, quality: 100) {
          ...GatsbyContentfulFixed
        }
      }
    }
  }

    allContentfulHeroHeader(filter: {contentful_id: {eq: "4IXhWxb6eXIMo1Uip9Pglv"}}) {
    nodes {
      headerImage {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      mobileImage {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      headerTitle
    }
    }
  
    contentfulCookie {
      cookieText {
       cookieText
      }
    }
  }
`;
